import { format, isToday, isTomorrow, isYesterday, isThisWeek, isThisYear } from 'date-fns';

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  if (isToday(date)) {
    return 'Today';
  } else if (isTomorrow(date)) {
    return 'Tomorrow';
  } else if (isYesterday(date)) {
    return 'Yesterday';
  } else if (isThisWeek(date)) {
    return format(date, 'EEEE'); // Returns the day of the week, e.g., 'Monday'
  } else if (isThisYear(date)) {
    return format(date, 'MMMM d'); // Returns the month and day, e.g., 'June 17'
  } else {
    return format(date, 'MMMM d, yyyy'); // Returns the full date, e.g., 'June 17, 2023'
  }

  // return timestamp;
};

export const ordinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const formatDate = (unixTimestamp) => {
  const milliseconds = unixTimestamp * 1000;

  // Create a new Date object
  const date = new Date(milliseconds);

  // Extract hours and minutes
  let hours = date.getHours();
  const minutes = ('0' + date.getMinutes()).slice(-2); // Add leading zero if needed

  // Determine AM/PM value
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours %= 12;
  hours = hours ? hours : 12; // If hours is 0, set it to 12

  return `${hours}:${minutes}${ampm}`;
};

export const getDierectResponsePercentage = (direct_response, used) => {
  if (used != 0) return ((100 * direct_response) / used).toFixed(2);
  else return 0;
};

export const getFormatedDateTime = (timestampString) => {
  if (timestampString != undefined) {
    const timestampNumber = parseFloat(timestampString);
    const timestampMilliseconds = timestampNumber * 1000;
    const date = new Date(timestampMilliseconds);
    const dateString = format(date, 'yyyy-MM-dd HH:mm:ss');
    return dateString;
  }
};

export const getWeekdayName = (weekday) => {
  switch (weekday) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '';
  }
};
export const checkIfTimeIsFuture = (time) => {
  const timestamp = time;

  // Get the current timestamp
  const currentTimestamp = Math.floor(Date.now() / 1000);

  // Check if the timestamp is in the future
  const isFutureTime = timestamp > currentTimestamp;
  return isFutureTime;
};

export function stringAvatar(name) {
  const trimmedName = name?.replace(/\s+/g, ' ').trim();
  const words = trimmedName?.split(' ')?.map((word) => word.trim());
  let avatarText;
  if (words?.length >= 2) {
    avatarText = `${words[0][0]}${words[1][0]}`;
  } else if (words !== undefined) {
    avatarText = `${words[0][0]}`;
    if (avatarText?.length > 1) {
      avatarText = avatarText.substring(0, 2);
    }
  }
  let tmp = stringToColor(name);
  return {
    sx: {
      width: '30px',
      height: '30px',
      fontSize: '15px',
      background: `hsl(${tmp[0]},${tmp[0]}%, ${tmp[1]}%)`
    },
    children: avatarText
  };
}
export function extractObjectfromString(str) {
  // Extracting the JSON-like object using regular expression
  const match = str.match(/\{.*\}/);

  if (match) {
    const jsonString = match[0].replace(/'/g, '"'); // Replace single quotes with double quotes
    const obj = JSON.parse(jsonString);

    return obj;
  } else {
    console.log('No object found in the string.');
  }
}

export function minutesToSeconds(minutes) {
  return minutes * 60;
}

export function secondsToMinutes(seconds) {
  return seconds / 60;
}

export function groupItemsByCategory(array) {
  const options = array?.map((option) => {
    const tag = option.split(': ')[0];
    return {
      tag: tag,
      option: option.split(': ')[1]
    };
  });
  const orderedTagOptionArray = options?.sort((a, b) => {
    const order = { Source: 0, Tag: 1, Other: 2 };
    return order[a.tag] - order[b.tag];
  });

  return orderedTagOptionArray;
}
export const getBaseUrl = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};

const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min);
};

const hRange = [0, 360];
const sRange = [50, 75];
const lRange = [25, 60];
const getHashOfString = (str) => {
  let hash = 0;
  if (str !== undefined)
    for (let i = 0; i < str?.length; i++) {
      hash = str?.charCodeAt(i) + ((hash << 5) - hash);
    }
  hash = Math.abs(hash);
  return hash;
};

const stringToColor = (name) => {
  const hash = getHashOfString(name);

  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);

  return [h, s, l];
};

export const downloadMedia = (e, originalImage) => {
  e.preventDefault();

  try {
    fetch(originalImage)
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;

        const nameSplit = originalImage.split('/');
        const duplicateName = nameSplit.pop();

        a.download = '' + duplicateName + '';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.log('error while downloading img', error.message));
  } catch (error) {
    console.log('error while downloading img', error.message);
  }
};
